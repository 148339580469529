<template>
    <div>
      <loading :loading="loading" :text="loadingText"></loading>
        <a :href="''+this.qrCodeUrl" target="_blank" v-if="this.loading==false">
          <img class="qrcode" :src="this.qrCodeUrl" />
        </a>
    </div>
</template>

<script>
    import {request, UrlEnum} from "../../../public/js/common-vue";
    import Loading from "../../components/Loading";

    export default {
        name: "QrCode",
      components: {Loading},
      data(){
            return{
              qrCodeUrl:'',
              loading:true,
              loadingText:""
            }
        },
        methods: {
            creatQrCode() {
              this.loading=true;
              let url = UrlEnum.COURSE_QRCODE+"?courseId="+request("courseId");
              this.$http({
                  method: "GET",
                  url: url,
                  data: '',
              })
              .then((res) => {
                  this.loading=false;
                  this.qrCodeUrl = res.data.qrCodeUrl;
              })
              .catch((res) => {
                  this.loading=false;
                  if(res.response != null){
                    const data = res.response.data;
                    this.showMsgError(data.message);
                  }
              });
            },
        },
        mounted() {
            this.creatQrCode();
        }
    }
</script>

<style scoped>
.qrcode{
    display: block;
    margin-top: 25px;
    margin-left: 40px;
}
</style>
